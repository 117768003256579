<template>
    <div class="page">
        <div class="login">
           <div class="login-title"><img src="../assets/image/tubiaologin.png"/></div>
           <div class="beijing-logn">
                <div class="beijing-left-ing"><img src="../assets/image/lefting_01.png"/></div>
                <div class="login-head-tilte">
                    <div class="login-head">
                      <div :class="{ account: true, checked: loginMethod == 1 }" @click="changeTab(1)">
                          账号密码登录
                      </div>
                      <div style="width: 4px; height: 21px; color: rgba(221, 221, 221, 1);margin: 0 12px;">/</div>
                      <div :class="{ code:true, checked: loginMethod == 2 }" @click="changeTab(2)">
                          验证码登录
                      </div>
                    </div>

                    <div class="login-content">
                      <div class="content_account" v-if="loginMethod == 1">
                          <el-form ref="loginForm" :model="loginForm" :rules="loginRules"> 
                                    <div class="form-title">手机号码</div>
                                    <el-form-item prop="Account">
                                        <div class="inpit-xiahuaxina">
                                            <el-input
                                                    v-model="loginForm.Account"
                                                    placeholder="请输入手机号"
                                                    type="text"
                                                    maxlength="11"
                                            >
                                            </el-input>
                                            <img style=" width: 24px; height: 24px;" src="@/assets/image/renyuan.png"/>
                                        </div>
                                    </el-form-item>
                                    <div class="form-title" style="margin-top: 39px;">密码</div>
                                    <el-form-item prop="Password">
                                        <div class="inpit-xiahuaxina">
                                        <el-input
                                                v-model="loginForm.Password"
                                                placeholder="请输入密码"
                                                type="password"
                                                tabindex="2"
                                        ></el-input>
                                        <img style=" width: 24px; height: 24px;" src="@/assets/image/suomin.png"/>
                                        </div>
                                    </el-form-item>
                          </el-form>
                      </div>
                      <div class="content_account" v-if="loginMethod == 2">
                          <el-form ref="codeForm" :model="codeForm" :rules="loginRules">
                              <div class="form-title">手机号码</div>
                              <el-form-item prop="phone">
                                <div class="inpit-xiahuaxina">
                                  <el-input
                                          v-model="codeForm.phone"
                                          placeholder="请输入手机号"
                                          type="text" 
                                          tabindex="1"
                                          maxlength="11"
                                  ></el-input>
                                   <img style=" width: 24px; height: 24px;" src="@/assets/image/renyuan.png"/>
                                </div>
                              </el-form-item>
                              <div class="form-title" style="margin-top: 39px;">验证码</div>
                              <el-form-item prop="code" >
                                <div class="inpit-xiahuaxina">
                                  <el-input
                                          v-model="codeForm.code"
                                          placeholder="请输入验证码"
                                          type="text"
                                          tabindex="2"
                                          maxlength="6"
                                  ></el-input>
                                  <div class="get-code" @click="(codeForm.phone.length  === 11 && !isCool) ? getCode() : ''"
                                      :style="{ cursor: isCool? 'not-allowed': 'pointer' }">
                                      <div :class="{ 'code-text': true, 'can-click': codeForm.phone.length  === 11 && !isCool }">
                                          {{ isCool ? `${ downTime } s后重发` : '获取验证码' }}
                                      </div>
                                  </div>
                                </div>
                              </el-form-item>
                          </el-form>
                      </div>
                      <div  class="login-btn">
                        <el-button :loading="loading" @click.native.prevent="handleLogin">立即登录</el-button>
                      </div>
                      
                      <div class="agree">
                          登录即表示同意
                          <!-- <div><a href="https://oaostest.bonnidee.cn/小邦管家《注册协议》.pdf" target="_blank">《注册协议》</a></div> -->
                          <div><a href="https://schoolapi.xiaobang.cloud/登录协议.pdf" target="_blank">《登录协议》</a>和<a href="https://schoolapi.xiaobang.cloud/隐私协议.pdf" target="_blank">《隐私协议》</a></div>
                      </div>
                    </div>
                </div>
           </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            loginForm: {
                Account: "",
                Password: "",
                IsPlatform: false,
            },
            // 验证码登录表单数据
            codeForm: {
                phone: '',
                code: ''
            },
            imglist: [],
            loginRules: {
                Account: [{
                    required: true,
                    message: '请输入手机号',
                    trigger: "blur",
                },],
                Password: [{
                    required: true,
                    message: '请输入密码',
                    trigger: "blur",
                },],
                phone: [
                    { required: true,message: '请输入手机号', trigger: 'blur',  }
                ],
                code: [
                    { required: true,message: '请输入验证码', trigger: 'blur', }
                ],
            },
            loading: false,
            passwordType: "password",
            redirect: undefined,
            loginMethod: 1, // 登陆方式： 1 密码  2 验证码
            codeIsRight: true, // 验证码是否正确，点击登录才会判断是否正确
            isCool: false, // 获取验证码是否处于冷缺时间
            downTime: 60, // 验证码冷却倒计时
            isDisabled: false, // 登录按钮是否禁用
            Detail:{},
            loginindex:1,
            shixiaoimg:false,
            QRcode:{},
            getloaginloop:null
        };
    },
    created() {
    

    },
    methods: {
        // 切换登录方式的方法
        changeTab(num) {
            if (num == 1) {
                // 切换到账号密码
                this.loginMethod = 1;
            } else {
                this.loginMethod = 2;
            }
        },
        // 登录接口
        handleLogin(){
            let that = this
            if( that.loginMethod == 1){
                that.$http({
                url: that.baseurl +"/apiLogin",
                method: "post",
                data:{
                    mobile:that.loginForm.Account,
                    password:that.loginForm.Password,
                    type:0
                }
                })
                .then(res => {
                     console.log(res,'res')
                    if(res.data.code==200){
                        console.log(res.data)
                        localStorage.setItem('auto_token',res.data.data.token);
                        // that.$message.success(res.data.msg);
                        that.$router.push('/')
                    }else{
                        that.$message.error(res.data.msg);
                        // that.$router.push('/')
                    }
                    
                })
                .catch(function(error) {
                    //  that.$message.error(res.data.message);
                });
            }else if(that.loginMethod == 2){
                that.$http({
                    url: that.baseurl +"/apiLogin",
                    method: "post",
                    data:{
                        mobile:that.codeForm.phone,
                        password:that.codeForm.code,
                        type:1
                    }
                })
                .then(res => {
                    console.log(res,'res')
                    if(res.data.code==200){
                        localStorage.setItem('auto_token',res.data.data.token);
                        // that.$message.success(res.data.msg);
                        that.$router.push('/')
                    }else{
                         that.$message.success(res.data.msg);
                    }
                    
                   
                })
                .catch(function(error) {});
            }
            
            
        },
        getCode() {
            let that = this
            // 调用获取二维码接口，成功后则执行如下操作。
             that.$http({
              url: that.baseurl +"/apiSendSms",
              method: "post",
              data:{
                mobile:that.codeForm.phone,
              }
            })
            .then(res => {
                if(res.data.code == 200){
                    that.$message.success(res.data.data)
                    that.isCool = true;
                    // 倒计时
                    let time = setInterval(() => {
                        that.downTime--;
                        if (that.downTime === 0) {
                            that.downTime = 60;
                            that.isCool = false;
                            clearInterval(time);
                        }
                    }, 1000)
                }else{
                    that.$message.error(res.data.msg)
                }
                
            })
            .catch(function(error) {
                console.log(error,'!!!!!')
                this.$message.error(error.msg)
            });
            
        },
    }
};
</script>
<style lang="css"  scoped>

.page {
  width: 100%;
  height: 100vh;
  background:url('../assets/image/loginbeijing.png') no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-title{
    width: 100%;
    display: flex;
    justify-content: center;
    /* padding-top: 242px; */
}
.login-title img{
    width: 609px;
    height: 58px;
}
.beijing-logn{
    display: flex;
    margin: 50px auto;
    background-color: #FFFFFF;
    width: 1032px;
    height: 585px;
    border-radius: 10px;
}
.login-head-tilte{
    width: 50%;
}
.beijing-left-ing{
    width: 50%;
    height: 100%;
}
.beijing-left-ing img {
    width: 100%;
    height: auto;
}
.login-head {
    height: 33px;
    margin-bottom: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 56px 46px 0 46px;
}
.account {
    height: 28px;
    line-height: 28px;
    font-size: 20px;
    color: rgba(134, 146, 166, 1);
    font-weight: 400;
    cursor: pointer;
}

.code {
    height: 28px;
    line-height: 28px;
    font-size: 20px;
    color: rgba(134, 146, 166, 1);
    font-weight: 400;
    cursor: pointer;
}

.checked {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    color: rgba(0, 106, 255, 1);
    font-weight: 600;
}

.content_account{
    padding: 0px 46px;
}        
.content_account .form-title {
    height: 25px;
    margin-bottom: 24px;
    line-height: 25px;
    font-size: 18px;
    color: #333333;
    font-weight: 550;
} 
.inpit-xiahuaxina{
    display: flex;
    border-bottom: 1px solid #DFDFDF;
}
.login-btn{
    width: 100%;
    text-align: center;
    margin-top: 80px;
}
.agree{
    display: flex;
    padding: 16px 46px;
    font-size: 14px;
    color: #8692A6;
    div {
        cursor: pointer;
   }
   a{
    color: #275AF5;
   }
}

.get-code {
  position: absolute;
  right: 0;
  top: 0;
  width: 103px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.code-text {
    width: 103px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    border-left: 1px solid #DCDCDC;
    font-size: 16px;
    color: #999999;
    user-select: none;
  }
  .can-click {
    color: #345CEA;
  }
</style>
<style>
.content_account .el-input__inner{
    border: none;
}
.content_account .el-input__inner{
    width: 404px;
    padding: 0;
}
.login-btn .el-button {
    width: 82%;
    height: 54px;
    font-size: 20px;
    background-color: #275AF5;
    color: #FFFFFF;
}
</style>
